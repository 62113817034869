<h5>Avis</h5>
<div class="bg-white shadow-sm p-3 mb-4" *ngFor="let avis of avis">
    <div class="align-items-center d-flex pb-1">
        <ng-template #t let-fill="fill">
            <span class="star" [class.full]="fill === 100">
                <span class="half" [style.width.%]="fill"><i class="fa-circle fas rounded-circle me-1" style="padding: 2px;border: 2px solid;height: 24px;width: 24px;"></i></span><i class="fa-circle fas rounded-circle me-1" style="padding: 2px;border: 2px solid;height: 24px;width: 24px;"></i>
            </span>
        </ng-template>

        <ngb-rating [(rate)]="avis.stars" [starTemplate]="t" [readonly]="readOnly" [max]="5"></ngb-rating>
        <span class="ms-auto link text-gray">
            {{date(avis.createdAt)}}
        </span>
    </div>
    <p class="my-2 text-gray">@
        <span *ngIf="avis.artiste">
            {{avis.artiste.profile.full_name}}
        </span>
        <span *ngIf="!avis.artiste">
            Utilisateur supprimé
        </span>
    </p>
    <h5 class="mb-3">“{{avis.comment}}”</h5>
</div>
<div class="p-3 mb-4 text-uppercase" *ngIf="avis.length==0">
    aucun avis trouvé
</div>