import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvisComponent } from './avis.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [AvisComponent],
  exports: [AvisComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AvisModule { }
