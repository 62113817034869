<div class="row g-0">
    <!-- set default card || delete card -->
    <div class="card-body d-flex justify-content-between" *ngIf="type=='manage'">
        <div class="">
            <img height="20" src="https://uploads-ssl.webflow.com/609acf33690f44a488af3497/609add1b4d3314d6d9ef7d07_Visa-Logo-2006-2014.png" alt="">
        </div>
        <div class="px-4 align-self-center d-md-flex justify-content-between col">
            <div class="card-text">**** **** **** {{card.last4}}</div>
            <div class="card-text">{{card.exp_month}}/{{card.exp_year}}</div>
        </div>
        <div class="align-self-center" >
            <i (click)="default(card.id)" class="fas fa-sync pointer fs-4"></i>
            <i (click)="delete(card.id)" class="fas fa-trash pointer fs-4 ms-2"></i>
        </div>
    </div>

    <!-- show card infos -->
    <div class="card-body d-flex justify-content-between" *ngIf="type!='manage'">
        <div class="px-4 align-self-center d-md-flex justify-content-between col">
            <div class="card-text">**** **** **** {{card.last4}}</div>
            <div class="card-text">{{card.exp_month}}/{{card.exp_year}}</div>
        </div>
        <div class="">
            <img height="20" src="https://uploads-ssl.webflow.com/609acf33690f44a488af3497/609add1b4d3314d6d9ef7d07_Visa-Logo-2006-2014.png" alt="">
        </div>
    </div>
</div>