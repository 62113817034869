import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  endPoint = "portfolios";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }
  create(data: any, medias: any): Observable<any> {    
    const formData = new FormData();
    medias.forEach((element:any) => {
      formData.append("files.photos", element);
    });
    formData.append("data", JSON.stringify(data));
    return this.http.post(environment.api + this.endPoint+ '/' , formData, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    }
    );
  }

  update(serviceId: string, data: any, medias: any): Observable<any> {
    const formData = new FormData();
    medias.forEach((element:any) => {
      formData.append("files.photos", element);
    });
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api + this.endPoint + '/' + serviceId, formData,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  find_by_id(serviceId: string) {
    return this.http.get(environment.api + this.endPoint + '/' + serviceId)
  }


  get_by_freelancer(id) {
    return this.http.get(environment.api + this.endPoint + '/?_sort=createdAt:DESC&freelance=' + id)
  }


  delete_by_id(serviceId: string) {
    return this.http.delete(environment.api + this.endPoint + '/' + serviceId, {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    })
  }
}
