import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FreelancerService {

  constructor(private http: HttpClient) { }

  get_freelancer_by_type_service_and_name(type: string, name: string): Observable<any> {
    let url = environment.api + "profiles/freelance?lat_ne&lng_ne";
    (type) ? url += "&type_service=" + type.replace("&", "%26") : "";
    (name != undefined && name) ? url = environment.api + `profiles/freelance?_q=${name.replace("&", "%26")}` : url;
    return this.http.get(url);
  }
  get_freelancer_by_id(type: string): Observable<any> {
    return this.http.get(environment.api + "profiles/freelance?id=" + type);
  }
}
