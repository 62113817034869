import { Component, Input, OnInit } from '@angular/core';
import { DateFormatterService } from 'src/app/services/date-formatter/date-formatter.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
  styles: [`
  .star {
    position: relative;
    display: inline-block;
    // font-size: 3rem;
    color: #d3d3d3;
  }
  .full {
    color: #E71831;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #E71831;
  }
`]
})

export class RatingComponent implements OnInit {
  @Input() avis:any;
  @Input() readOnly:any;


  constructor(private dateService:DateFormatterService) { }

  ngOnInit(): void {
  }

  date(value:string){
    return this.dateService.showLocalFormat(value)
  }

}
