import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';



@Injectable({
  providedIn: 'root'
})
export class ServiceApi {

  endPoint="services";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }
  create(form: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint, JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    }
    );
  }

  update(serviceId: string, data: any): Observable<any> {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this.http.put(environment.api +this.endPoint+ '/' + serviceId, formData,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

  find_by_id(serviceId:string){
    return this.http.get(environment.api+ this.endPoint+'/'+ serviceId)
  }
  
  
  get_by_freelancer(freelancerId){
    return this.http.get(environment.api+this.endPoint+ '/?_sort=createdAt:DESC&freelance='+ freelancerId)
  }
  
  
  delete_by_id(serviceId:string){
    return this.http.delete(environment.api+ this.endPoint+'/'+ serviceId,{
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
    })
  }


}
