export class Hour {
    text: string;
    color: string;
    hour: string;
    position: number;
    busy: boolean;
    disabled: boolean;
    reduction: number;

    constructor(text: string, hour: string, position: number, busy: boolean, checked: boolean,reduction:number) {
        this.text = text;
        this.hour = hour;
        this.busy = busy;
        this.position = position;
        this.reduction = reduction;
        this.color = (checked) ? "bg-danger" : (busy) ? "bg-light-gray" : "bg-white";
    }

    getReduction(){
        return "-"+this.reduction+"%";
    }

    setBusy(){
        this.busy=true;
        this.color="bg-light-gray";
    }
    choosen(){
        this.color="bg-danger text-white";
    }
    unselect(){
        this.color="bg-white";
    }
}
