import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  endPoint = "avis";
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }


  create(form: any): Observable<any> {
    return this.http.post(environment.api + this.endPoint, JSON.stringify(form),
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(),'content-type':'application/json' }
      }
    );
  }
}
