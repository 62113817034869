export class Card {
    id: string;
    last4: string;
    exp_month: string;
    exp_year: string;
    crypto: string;
    brand: string;
    checked: boolean;
    constructor(data: any, defaultId: string) {
        this.id = data["id"],
        this.brand = data["card"]["brand"],
        this.exp_month = data["card"]["exp_month"].toString(),
        this.exp_year = data["card"]["exp_year"].toString(),
        this.last4 = data["card"]["last4"].toString(),
        this.checked = this.id == defaultId;
    }
}
