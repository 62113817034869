import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReuseRouteService } from 'src/app/services/reuse-route/reuse-route.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit, AfterViewInit {
  @Input() card: any;
  @Input('type') type: any;
  @Output() cardAction = new EventEmitter<any>();
  params: any;
  constructor(private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private stripeApi: StripeService, private refresh: ReuseRouteService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    })
  }

  ngAfterViewInit(): void {
    this.setIFrameHeight();
  }

  default(cardId: string) {
    if (this.isIFrame()) {
      this.cardAction.emit({ carte: cardId, action: "default", text: "Êtes-vous sûr de vouloir définir cette carte par défaut ?" });
    } else {
      Swal.fire({
        text: 'Êtes-vous sûr de vouloir définir cette carte par défaut ?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `OUI`,
        cancelButtonColor: `#FF0000`,
        cancelButtonText: `NON`,
        grow: "fullscreen"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.stripeApi.defaultPaymentMethod(cardId).subscribe(res => {
            Swal.fire("Bien!", "La carte a été mis par défault avec succès", 'success');
          }, err => {
            Swal.fire("Érreur", "une erreur s'est produite veuillez réessayer", 'error');
          }).add(() => {
            this.spinner.hide();
          })
        }
      })
    }

  }
  delete(cardId: string) {
    if (this.isIFrame()) {
      this.cardAction.emit({ carte: cardId, action: "delete", text: "Êtes-vous sûr de vouloir supprimer cette carte ?" });
    } else {
      Swal.fire({
        text: 'Êtes-vous sûr de vouloir supprimer cette carte?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: `OUI`,
        cancelButtonColor: `#FF0000`,
        cancelButtonText: `NON`,
        grow: "fullscreen"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.stripeApi.deletePaymentMethod(cardId).subscribe(res => {
            Swal.fire("Bien!", "La carte a été supprimée avec succès", 'success')
          }, err => {
            Swal.fire("Érreur", "une erreur s'est produite veuillez réessayer", 'error');
          }).add(() => {
            this.spinner.hide();
          })
        }
      })
    }
  }

  isIFrame() {
    return typeof window !== 'undefined' && typeof parent !== 'undefined' && window != parent;
  }

  setIFrameHeight() {
    if (this.isIFrame()) {
      setTimeout(() => {
        const elementHeight = document.body.scrollHeight;
        parent.postMessage(JSON.stringify({ iframeHeight: elementHeight }), environment.webflowURL);
      }, 200);
    }
  }
}
