import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class MediasService {

  constructor(private httpClient: HttpClient,private tokenStorageService:TokenStorageService) { }

  delete_pictures(pictureId:string){
    return this.httpClient.delete(environment.api + "upload/files/" + pictureId,
      {
        headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken() }
      });
  }

}
