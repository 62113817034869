import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { RatingService } from 'src/app/services/rating/rating.service';
import { ReuseRouteService } from 'src/app/services/reuse-route/reuse-route.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-avis',
  templateUrl: './avis.component.html',
  styleUrls: ['./avis.component.css'],
  styles: [`
  .star {
    position: relative;
    display: inline-block;
    // font-size: 3rem;
    color: #d3d3d3;
  }
  .full {
    color: #E71831;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #E71831;
  }
`]
})
export class AvisComponent implements OnInit {
  Form: FormGroup;
  submitted=false;
  rate=1;
  @Input() studio:any;
  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private tokenService:TokenStorageService,
    private ratingService:RatingService,
    private refresh: ReuseRouteService,
  ) { 
    this.Form = this.fb.group({
      stars: [],
      comment: ["", Validators.required],
      studio: [],
      artiste: [this.tokenService.getUser().id],
    })
  }

  ngOnInit(): void {
    this.Form.controls.studio.setValue(this.studio);
  }

  onSubmit(){
    this.Form.controls.stars.setValue(this.rate);
    this.submitted = true;
    if (this.Form.invalid) {
      Swal.fire('Érreur', 'Veuillez Laisser un commentaire', 'info');
      return;
    }
    this.spinner.show();
    this.ratingService.create(this.Form.value).subscribe(res=>{
      Swal.fire('Félicitation', 'votre commentaire a été envoyé.', 'success');
      this.refresh.reloadComponent("/artiste/fiche-studio/"+this.studio);
      document.getElementById("closerateModal").click();
    },err=>{
      Swal.fire('Érreur', 'Une érreur est survenu', 'error');
    }).add(()=>{
      this.spinner.hide();
    })
  }

  cancel() {
    document.getElementById("closerateModal").click();
  }

}
