import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrestationsService {

  constructor(private http: HttpClient) { }

  get_prestation_by_type(type:string): Observable<any> {
    return this.http.get(environment.api + "prestations?type_prestation="+type+"&_sort=IsSponsored:DESC,user:DESC");
  }
  
  get_prestations(): Observable<any> {
    return this.http.get(environment.api + "prestations");
  }

}
