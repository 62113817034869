<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#rate"
    id="openrateModal">
    Launch demo modal
</button>

<!-- Modal -->
<form [formGroup]="Form">
    <div class="modal fade" id="rate" tabindex="-1" aria-labelledby="rateLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <button id="closerateModal" type="button" class="btn-close float-end"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-2">
                        <h3 class="mt-3">Laisser un avis</h3>
                        <p>Merci d'attribuer une note à ce studio</p>
                        <div class="my-3">
                            <ng-template #t let-fill="fill">
                                <span class="star" [class.full]="fill === 100">
                                    <span class="half" [style.width.%]="fill"><i class="fa-circle fas rounded-circle me-1" style="padding: 2px;border: 2px solid;height: 24px;width: 24px;"></i></span><i class="fa-circle fas rounded-circle me-1" style="padding: 2px;border: 2px solid;height: 24px;width: 24px;"></i>
                                </span>
                            </ng-template>
                            <ngb-rating [(rate)]="rate" [starTemplate]="t" [readonly]="false" [max]="5"></ngb-rating>
                        </div>
                        <textarea placeholder="Votre commentaire" formControlName="comment" class="form-control" rows="5"></textarea>
                       
                        <button (click)="onSubmit()" type="button"
                            class="btn btn-danger text-uppercase w-100">confirmer
                        </button>
                        <button type="button" class="btn btn-outline-gray mt-3 text-uppercase w-100"
                            (click)="cancel()">annuler
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>