import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
  }

  create(form:any): Observable<any> {
    // set null to 0 in tarif promo to avoid errors
    form['array'].map(x => x.tarif_promo = (x.tarif_promo) ? x.tarif_promo : '0');
    return this.http.post(environment.api + 'types', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(),"content-type":"application/json" }
    });
  }

  delete(form:any): Observable<any> {
    return this.http.post(environment.api + 'types/delete', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(),"content-type":"application/json" }
    });
  }

  multiUpdate(form: any): Observable<any> {
    return this.http.post(environment.api + 'types/update', JSON.stringify(form), {
      headers: { Authorization: 'Bearer ' + this.tokenStorageService.getToken(), "content-type": "application/json" }
    });
  }
}